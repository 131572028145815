/// <reference types="@types/googlemaps" />
import { populateEnv } from '@env';
import {localEnvironment} from './environments';

async function startApplication() {

  populateEnv(localEnvironment);

  const envResponse: Response = await fetch(`/assets/json/env/env.json?v=${Date.now()}`);
  const envJson = await envResponse.json();

  populateEnv(envJson);

  const { setup } = await import('./app-setup');
  await setup();
}

startApplication()
  .then(() => console.log('Área de clientes inicializada'))
  .catch((err) => {
    console.error(err);
    alert('Falha ao iniciar a Área de Clientes');
  });
