import { Environment } from "@env";


export const localEnvironment: Environment = {
  appTokenPrefix: 'MH0',
  appId: 'pt.tranquilidade.mytranquilidade',
  isMobile: false,
  isMyLogo: false,
  serviceAppCompany: '001',
  serviceAppChannel: '013',
  sslPinningConfig: {
    enabled: false,
  },
  storageCryptoKey: 'i3kjqq6fzY0p0fy',
  rsaKey:
    'mVSswB7lsBDb9ibP4UZ4svSKe4+Yis5j7V3Tr5LhvBFYojyj1K7NC7/fXV/bmduJ9bKc3oUrX0OHz+/L6y8pCTAkLD3F1z6TcYF6eT4S1Q+1qWwl5btWMVq912KWHqP9scNoXbnbdU/M9ZQtaWuPngpb0OB3cw7+4CUsdDeMVEs=',
  rsaKeyExp: 'AAEAAQ==',
  rsaKeySimulation:
    '69biyd7XxrmilHC+M7dWJzQxKDC9VO+Mt3yBO7ylJTfsNl+81QhI/GlR09eU5kEPpqvKKzWnHeHe1PYVsfgO17pqaZmd/U/mL0ERaPt+mZSiSjiM2VEpWGYWaHNyQMlUXne3Rmp2iDVft9r4Hymvec7l0mtk8SJOglaL9mZxLWk=',
  rsaKeyExpSimulation: 'AQAB',
  mapsKey: 'AIzaSyB4n-rCecKcnqWdmhH7Wvl5ThNH1nSX3VA',
  password: 'kaGF35&3cv!64a4',
  systemIntegrityCheck: false,
  clientId: 'WebHub',
  storeIds: {
    android: 'com.tranquilidade',
    ios: 'id1258182237',
    huawei: 'C105642169',
  },
  homeAssistanceUrl: 'https://uat.home-digital-assistance.com/?token=TG5KWGN6F9',
};
